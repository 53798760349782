import React, { Component } from 'react';

import DiagonalSplitImage from '../../../images/page_banners/Parents_header_desktop.png';
import DiagonalSplitImageTablet from '../../../images/page_banners/Parents_header_tablet.png';
import DiagonalSplitImageMobile from '../../../images/page_banners/Parents_header_mobile.png';

import { CallToAction } from '../../../typings/CallToAction.type';
import { DiagonalSplitBanner } from '../../../components/DiagonalSplitBanner/DiagonalSplitBanner';
import { StyledParentPage } from './Parent.styled';
import { Size } from '../../../typings/Size.type';
import { HalfImageHalfText } from '../../../components/HalfImageHalfText/HalfImageHalfText';
import HalfImage from '../../../images/parents/Parents_desktop.png';
import { HowItWorks } from '../../../components/HowItWorks/HowItWorks';
import { ParentsHowItWorksContent } from '../../../contents/howItWorks';
import { withRouter } from 'react-router-dom';

class ParentPage extends Component<any> { 
  render() {

  const diagonalBannerCallToAction: CallToAction = {
    heading: 'PARENTS',
    subHeading: `Ahead of the Game helps parents develop the skills and knowledge they need to understand, recognise and support the mental health and wellbeing of their teenagers.
    
As part of the programme, parents will attend one workshop. The session will help you recognise the warning signs of mental health problems and learn how to start a conversation about mental health. At the end, you'll also have a deeper understanding of the support channels available to you.  
    `,
    callToActionLabel: 'Register',
    onClick: (): void => this.props.history.push({pathname: '/contact', search: 'parent'})
  };

  const slides = [
    {
      image: HalfImage,
      heading: 'Your role in adolescent mental health',
        // tslint:disable-next-line: max-line-length
      subHeading: 'Learn about some of the tough stuff like how to take action on your teen\'s mental health.'
    },
    {
      image: HalfImage,
      heading: 'How to tell if your teen might be struggling',
      // tslint:disable-next-line: max-line-length
      subHeading: 'Make sure that you have your teen\'s back by understanding when they might be struggling, and how you might be able to help.'
    },
    {
      image: HalfImage,
      heading: 'Having important conversations',
      // tslint:disable-next-line: max-line-length
      subHeading: 'Talking about mental health can be awkward. Learn how to start a conversation with your teen.'
    },
      
  ];

  return (
    <StyledParentPage>
      <DiagonalSplitBanner callToAction={diagonalBannerCallToAction} images={[DiagonalSplitImage, DiagonalSplitImageTablet, DiagonalSplitImageMobile]} size={Size.small}/>
      <HalfImageHalfText slides={slides} />
      <HowItWorks numberOfTimelineComponents={2} content={ ParentsHowItWorksContent } />
    </StyledParentPage>
  );
  }
}

const Parent = withRouter(ParentPage);

export { Parent as ParentPage };