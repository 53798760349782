import React from 'react';
import { StyledDocumentaryContainer, StyledDocumentatryContent, StyledDocumentaryHeading, StyledDocumentaryBlurb, StyledDocumentaryButton, StyledDocumentaryVideoContainer, StyledButtonContents, StyledDocumentaryVideo, StyledDocumentaryVideoContent} from './DocumentarySection.styled';
import { ReactComponent as RightArrow } from '../../images/icons/right_icon.svg';
import { withRouter } from 'react-router-dom';

export const DocumentarySection = withRouter((props: any) => {
    
    return <StyledDocumentaryContainer>
        <StyledDocumentatryContent>
            <StyledDocumentaryHeading level="h3">Ahead of the Game Documentary</StyledDocumentaryHeading>

            <StyledDocumentaryBlurb>The Ahead of the Game documentary tells the story of how Movember is working with some of rugby league’s biggest stars to deliver mental fitness workshops to thousands of young people. Watch the trailer below or click the link for the full documentary.</StyledDocumentaryBlurb>

            <StyledDocumentaryButton
                primary={true} 
                onClick={() => {
                    props.history.push('/documentary#watchVideo');
                }}>
                <StyledButtonContents>
                    <RightArrow/>
                </StyledButtonContents>
            </StyledDocumentaryButton>
        </StyledDocumentatryContent>

        <StyledDocumentaryVideoContainer>
            <StyledDocumentaryVideoContent>
                <StyledDocumentaryVideo frameBorder="0" src="https://www.youtube.com/embed/XuWcrdkyjOo" />
            </StyledDocumentaryVideoContent>
        </StyledDocumentaryVideoContainer>
    </StyledDocumentaryContainer>;
});