import React from 'react';
import { StyledGetHelpContainer,
         StyledHeading,
         StyledButtonContainer,
         StyledGoToTopButton,
         StyledIcon
        } from './GetHelp.styled';
import { UkInfo, IrelandInfo } from './InfoPanel/InfoPanel';
import {  Text } from '@movember/mo-gel';
import { ReactComponent as Arrow } from '../../images/icons/right_icon.svg';

export const GetHelp = (props: any) => {
    return (
        <StyledGetHelpContainer>
            <StyledHeading level="h3" color="black">
                Organisations
            </StyledHeading>
            <Text as="h2" color="black" marginBottom="34px" display="block">
                The following organisations provide support and further resources on mental health. 
            </Text>
            <Text as="p" color="black" marginBottom="34px" display="block">
                If a person is in crisis or life is in danger, call 999.</Text>
            <UkInfo />
            <IrelandInfo/>
            <StyledButtonContainer>
                <StyledGoToTopButton onClick={gotoTop}>
                    <StyledIcon>
                        <Arrow />
                    </StyledIcon>
                </StyledGoToTopButton>
            </StyledButtonContainer>
        </StyledGetHelpContainer>
    );
};

function gotoTop(): void {
    if (window.scrollY > 0) {
        setTimeout(() => {
            window.scrollTo(0, window.scrollY - 20);
            gotoTop();
        }, 5);
    }
}