import React, { useRef } from 'react';
import { StyledPage,
        StyledLink,
        StyledHeadingContainer, 
        StyledHeadingContent,
        StyledVideoContainer,
        StyledVideoContent,
        StyledHeadingText,
        StyledWatchContainer,
        StyledText,
        StyledDocumentaryVideo,
        StyledDocumentaryVideoContainer,
        StyledDocumentaryVideoContent} from './Documentary.styled';
import { Heading} from '@movember/mo-gel';
import HalfImage from '../../images/about/halfimage.png';
import { HalfImageHalfText } from '../../components/HalfImageHalfText/HalfImageHalfText';

export const DocumentaryPage = (props: any) => {
    const documentaryPlayerRef = useRef(null) 

    const slides = [
      {
        image: HalfImage,
        heading: 'MENTAL FITNESS WORKSHOPS',
          // tslint:disable-next-line: max-line-length
        subHeading: `Ahead of the Game tells the story of how Movember is working with Rugby League Cares and some of the UK’s biggest rugby league stars to deliver mental fitness workshops to thousands of young people across the north of England.`,
        button: {
            label: 'LEARN MORE',
            onClick: () => {
                props.history.push('/contact');
            }
        }
      }
    ];

    return (
        <StyledPage>
            <StyledHeadingContainer>
                <StyledVideoContainer>
                    <StyledVideoContent>

                        <video autoPlay loop muted id="backgroundVideo">
                            <source src="./aotg-background.mp4" type="video/mp4" />
                        </video>
                    </StyledVideoContent>
                </StyledVideoContainer>
                <StyledHeadingContent>
                    <StyledHeadingText>
                        <Heading level="h3" color="white" marginBottom="24px">
                            Ahead of the Game Documentary
                        </Heading>
                        <StyledText>
                            Ahead of the Game tells the story of how Movember is working with Rugby League Cares and some of the UK’s biggest rugby league stars to deliver mental fitness workshops to thousands of young people across the north of England. 
                        </StyledText>
                        <StyledLink to="#watchVideo" onClick={() => documentaryPlayerRef.current && (documentaryPlayerRef.current as any).scrollIntoView()}>
                            Watch the documentary below (47 mins)
                        </StyledLink>
                    </StyledHeadingText>
                </StyledHeadingContent>
            </StyledHeadingContainer>
            <StyledWatchContainer id="watchVideo" name="watchVideo" ref={documentaryPlayerRef}>
                <Heading level="h4" color="black" marginBottom="24px">
                    Watch the Documentary
                </Heading>
                
                <StyledDocumentaryVideoContainer>
                    <StyledDocumentaryVideoContent>
                        <StyledDocumentaryVideo  frameBorder="0" src="https://www.youtube.com/embed/jiQ-D6kfX1o" />
                    </StyledDocumentaryVideoContent>
                </StyledDocumentaryVideoContainer>
            </StyledWatchContainer>
            <HalfImageHalfText slides={slides} layout="mobileOnTabletPortrait" />
        </StyledPage>
    );
};