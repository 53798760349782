import styled from 'styled-components';
import { Heading, Text } from '@movember/mo-gel';
import { Link } from 'react-router-dom';

export const StyledInfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap:wrap;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        flex-direction: row;
        width: 689px;
        justify-content: center;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        width: 1017px;
        justify-content: space-evenly;
    }
`;

export const StyledInfoPanel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 31px;
    min-height: 100px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        word-break: break-word;
        text-align: left;
        flex: 40%;
        justify-content: space-between;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        align-items: flex-start;
        flex: 25%;
    }
`;

export const StyledHeading = styled(Heading)`
    font-size: 25px;
    line-height: 26px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        width: auto;
        font-size: 25px;
        line-height: 30px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        font-size: 25px;
        line-height: 30px;
    }
`;

export const StyledLocationHeading = styled(Heading)`
    font-size: 33px;
    line-height: 33px;
    font-weight: 700;
    margin-bottom: 34px;
`; 

export const StyledLink = styled(Link)`
    text-decoration: none;
    pointer-events: none;
    text-decoration-color: ${(props: any) => props.theme.colors.primaryBrand1};
    color: black;

    :hover {
        color: ${(props: any) => props.theme.colors.primaryBrand1};
    }
`;

export const StyledText = styled(Text)`
    color: ${(props: any) => props.theme.colors.black};
    font-weight: bold;

    :hover {
        color: ${(props: any) => props.theme.colors.primaryBrand1};
    }

    a {
        text-decoration: none;
        color: inherit;
    }
`;