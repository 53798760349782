export const colors = {
    white: '#ffffff',
    black: '#000000',
    primaryBrand1: '#000000',
    primaryBrand2: '#C4C4C4',
    primaryBrand3: '#E5E5E5',
    primaryBrand4: '#F8F8F8',
    secondaryBrand1: '#ffffff',
    secondaryBrand2: '#eeeeee',
    secondaryBrand3: '#f2f5f7',
    primaryFont: '#f6f6f6',
    content: '#000000',
    accentFont: '#016ef7',
    accentLink: '#006af9',
    error: '#ff3838',

    buttonDarkHover: '#808080',
    buttonLightHover: 'lightgray',

    primaryDarkFont: '#0c1e2c',
    primaryLightFont: '#ffffff',
  
    // warning colors: orange and yellow
    warning1: '#ff8500',
    warning2: '#ffdd00',
  
    // darkest/worse (1) to lightest/best (3)
    success1: '#7ac121',
    success2: '#7ad521',
    success3: '#7aeb21'
  };
