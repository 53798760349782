import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text} from '@movember/mo-gel';

export const StyledPage = styled.div`
`;

export const StyledHeadingContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    padding-bottom: 400px;
    
    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        padding-bottom: 550px;
    }
    
    @media screen and (max-width: ${(props: any) => props.theme.breakpoints.xxs}) {
        padding-bottom: 320px;
    }


    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.md}) {
        padding-bottom: 0px;
    }
`;

export const StyledHeadingContent = styled.div`
    position: relative;
    padding:15px;
    padding-top: 120px;
    text-align: center;

    background: ${(props: any) => props.theme.colors.primaryBrand1};

    :before {
        content: '';
        display: block;
        position: absolute;
        top: calc(100% - 40px);
        left: 0px;
        width: 0px;
        height: 0px;
        border-bottom: 200px solid transparent;
        border-left: 1408px solid black;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        padding:40px;
        padding-top: 180px;
    }
    
    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.md}) {
        max-width: 600px;
        text-align: left;
        padding: 200px 140px;
        padding-right: 0px;
        
        :before {
            content: '';
            display: block;
            position: absolute;
            top: 0px;
            left: calc(100% - 90px);
            width: 0px;
            height: 0px;
            border: 0px solid transparent;
            border-bottom: 1400px solid transparent;
            border-left: 240px solid black;
        }
    }
`;

export const StyledHeadingText = styled.div`
    position: relative;
`;

export const StyledText = styled(Text)`
    display: block;
    padding: 0px 120px;
    @media screen and (max-width: ${(props: any) => props.theme.breakpoints.sm}) {
        padding: 0px;
    }
    
    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.md}) {
        padding: 0px;
    }
`;

export const StyledLink = styled(Link)`
    display: block;
    margin-top: 24px;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-family: ${(props: any) => props.theme.typography.contentFontFamily}, sans-serif;
`;

export const StyledVideoContainer = styled.div`
    position: absolute;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
`;

export const StyledVideoContent = styled.div`
    position: absolute;
    right:0px;
    bottom:0px;
    height:100%;
    
    height: calc(100vw * 0.15 + 240px);
    width: 100vw;
    
    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        height: calc(100vw * 0.15 + 390px);
    }
    
    @media screen and (max-width: ${(props: any) => props.theme.breakpoints.xxs}) {
        height: calc(100vw * 0.15 + 160px);
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.md}) {
        height: 100%;
        width: calc(100% - 740px);
    }

    video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
    }
`;

export const StyledWatchContainer = styled.div`
    display: block;
    position: relative;
    background: white;
    text-align: center;

    padding-top: 82px;
    padding-bottom: 82px;
`;

export const StyledDocumentaryVideoContainer = styled.div`
    max-width: 1080px;
    width: 90%;
    margin: auto;
    margin-top: 48px;

    @media screen and (max-width: ${(props: any) => props.theme.breakpoints.sm}) {
        margin-top: 24px;
    }
`;

export const StyledDocumentaryVideoContent = styled.div`
    position: relative;

    text-align: center;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    height: 0;
    
`;

export const StyledDocumentaryVideo = styled.iframe`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
`;