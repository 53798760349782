export const HowItWorksContent = {
    heading: "HOW IT WORKS",
    introText: "Ahead of the Game is delivered at sport training — right where sport teams already are. We come to you and make having mental health conversations easier.",
    subsections: [
        {
            subheading: "WHEN ARE WORKSHOPS HELD?",
            text: "During regular sport training times. (Usually before, during or immediately after a regular training).",
            link: ""
        },
        {
            subheading: "WHO PARTICIPATES?",
            text: "Athletes, parents and coaches will each participate in interactive workshops with their group. Sessions for all groups are run on a single day, with a second workshop for athletes about a week later. We also provide online tools and info that can be accessed at any time.",
            link: ""
        }       
    ],
}

export const WhyItWorksContent = {
    heading: "WHY IT WORKS",
    introText: "Ahead of the Game is based on Movember-funded research. It engages sporting organisations as a way to improve the mental health and resilience of young athletes.",
    subsections: [
        {
            subheading: "WE USE THE POWER OF SPORT",
            text: "The programme is effective because it uses sport to help participants acquire important skills and knowledge. The programme is run at sport training sessions to engage participants and emphasise lessons that can be taught through sport.",
            link: ""
        },
        {
            subheading: "WE ENGAGE ALL STAKEHOLDERS",
            text: "With programmes for athletes, parents and coaches, Ahead of the Game can help young athletes and their support networks promote mental health, resilience and wellbeing.",
            link: ""
        },
        {
            subheading: "WE FOCUS ON THE SKILLS YOU NEED",
            text: "Ahead of the Game targets both mental health literacy and resilience. When combined, these skills can underpin mental health and wellbeing over the decades to come.",
            link: ""
        }                
    ],
}