import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { StyledFaqPage, 
      StyledFaqHeading,
      StyledFaqContact,
      StyledFaqContactInner,
      StyledFaqContent,
      StyledQuestionText,
      StyledAnswerContent,
      StyledFaqAccordion } from './Faq.styled';
import { Heading, Button } from '@movember/mo-gel';
import { ReactComponent as RightArrow } from '../../images/icons/right_icon.svg';

class FaqPage extends Component<any, any> {
  render() {
    const faqItems = [{
      question: `What is Ahead of the Game?`,
      content: <StyledAnswerContent>Movember Ahead of the Game is a training workshop that uses sport to teach athletes, parents and coaches how to talk about mental health. The programme reviews how to recognise mental health issues, what to do and when to get help. Athletes learn how to build resilience and overcome challenges in sport and life.</StyledAnswerContent>
    }, {
      question: `Why is it needed?`,
      content: <StyledAnswerContent>The prevalence of mental health problems among adolescents is alarmingly high. With lower rates of accessing services than young women, young men and boys represent a group at high risk of developing mental health problems. Research has shown that organised sport is an effective and important avenue for supporting mental health.</StyledAnswerContent>
    }, {
      question: `When does Ahead of the Game take place?`,
      content: <StyledAnswerContent>Delivery of Movember Ahead of the Game is flexible and it can be delivered during regular sport training times. (Usually before, during or immediately after a regular training). We come to you and make having mental health conversations easier.</StyledAnswerContent>
    }, {
      question: `Who participates?`,
      content: <StyledAnswerContent>Athletes, parents and coaches will each participate in interactive workshops with their group. Sessions for all groups are run on a single day, with a second workshop for athletes about a week later. We also provide online tools and info that can be accessed at any time.</StyledAnswerContent>
    }, {
      question: `How many workshops do I need to attend?`,
      content: <StyledAnswerContent>As part of the programme, athletes will attend two 1-hour workshops where they’ll learn the skills that will help improve their understanding of mental health and resilience. Parents will attend one workshop developed specifically for parents and coaches will attend a workshop for coaches.</StyledAnswerContent>
    }, {
      question: `Why is my club taking part?`,
      content: <StyledAnswerContent>The prevalence of mental health problems among adolescents is alarmingly high. Young men and boys represent a group at high risk of developing mental health problems. Research has shown that organised sport is an effective and important avenue for supporting mental health.</StyledAnswerContent>
    }, {
      question: `Do I have to participate?`,
      content: <StyledAnswerContent>No, you do not need to participate in the workshops, or in workshop surveys. You are free to complete, part, or none of the programme as you wish. You will receive information prior to the programme which will help you make a decision on whether you want to take part.</StyledAnswerContent>
    }, {
      question: `Can I withdraw from the programme?`,
      content: <StyledAnswerContent>Yes, you can withdraw from the programme at any time. If you choose to withdraw, this will not affect your relationship with your team, sport organisation, or Movember in any way.</StyledAnswerContent>
    }, {
      question: `Will I be expected to treat mental health problems?`,
      content: <StyledAnswerContent>No, you will not be responsible for treating any mental health problems. The programme is designed so that people will understand how to access help from an appropriate service, if necessary. The project team can help with any such queries throughout the project.</StyledAnswerContent>
    }, {
      question: `What if an athlete wants to participate but their parent doesn’t, can the athlete participate without their parents?`,
      content: <StyledAnswerContent>Yes, athletes can participate even if their parent, guardian or their coach do not wish to participate. However, adolescents need their parent or guardian’s consent to participate in the programme.</StyledAnswerContent>
    }, {
      question: `As a parent can I be part of the athletes workshop?`,
      content: <StyledAnswerContent>The athlete’s workshop is just for adolescents. However, in the parent programme we will let you know exactly what your adolescent has learned in their programme so that you can be prepared.</StyledAnswerContent>
    }, {
      question: `Is the programme offered to female athletes?`,
      content: <StyledAnswerContent>Movember Ahead of the Game can be delivered to any gender but is targeted at young men due to the disproportionate effect that poor mental health has on men and their lives. To learn more about how the programme works, contact our expert Movember Coordinators.</StyledAnswerContent>
    }, {
      question: `How do I register or find out more?`,
      content: <StyledAnswerContent>To register your interest or find out more head to the contact us section of the website.</StyledAnswerContent>
    }, {
      question: `Is there anything I need to do before my workshops?`,
      content: <StyledAnswerContent>Athletes need to complete and return a consent form before taking part and there is a short survey to complete.</StyledAnswerContent>
    }, {
      question: `Is the programme offered to girls' rugby/sport?`,
      content: <StyledAnswerContent>Movember Ahead of the Game can be delivered to any gender but is targeted at young men due to the disproportionate effect that poor mental health has on men and their lives. To learn more about how the programme works, contact our expert Movember Coordinators.</StyledAnswerContent>
    }];

    return (
        <StyledFaqPage>
            <StyledFaqHeading level="h3">Frequently Asked Questions</StyledFaqHeading>
            <StyledFaqContent>
              <StyledFaqAccordion allowMultipleOpen={true}
                items={faqItems.map(faqItem => {
                  return {
                    header: <StyledQuestionText>{faqItem.question}</StyledQuestionText>,
                    content: faqItem.content
                  }
                })}
                >
              </StyledFaqAccordion>
            </StyledFaqContent>
            <StyledFaqContact>
              <StyledFaqContactInner>
                <Heading level="h5" color="black">Can't see your question here?</Heading>
                <Button 
                    primary={true}
                    width="194px"
                    onClick={() => this.props.history.push("/contact")}
                    >Contact us<RightArrow/></Button>
              </StyledFaqContactInner>
            </StyledFaqContact>
        </StyledFaqPage>
    );
  }
}

const component = withTheme(withRouter(FaqPage));
export { component as FaqPage };