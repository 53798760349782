import  styled, { css, createGlobalStyle } from 'styled-components';

const globalImports = css`
  @import url('https://fonts.googleapis.com/css?family=Passion+One&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Chivo&display=swap');
  @import url('https://fonts.googleapis.com/css?family=AktivGrotesk&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Teko&display=swap');
`;

export const RootStyle = createGlobalStyle`
  ${globalImports}
  :root {
    font-size: 16px;
    line-height: 24px;

    h1, h2, h3, h4, h5, h6 {
      font-family: ${(props: any) => props.theme.typography.defaultFontFamily}, sans-serif;
      font-weight: 300;
      text-transform: uppercase;
    }
    
    p, span {
      font-family: ${(props: any) => props.theme.typography.contentFontFamily}, sans-serif;
    }

    body {
      margin: 0;
      position: relative;
    }
  }
`;

export const StyledApp = styled.div`
  /* min-height: 100vh; */
  position: relative;
  padding-bottom: 532px;

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
    padding-bottom: 284px;
  }

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
    padding-bottom: 227px;
  }
`;