import React, { Component } from 'react';

import { StyledCarouselContainer
} from './HalfImageHalfText.styled';

export interface HalfImageHalfTextProps {
  layout?: string;
}

export class HalfImageHalfTextWithExtendedProps extends Component<HalfImageHalfTextProps, any> {

  // recursively clone children and pass the layout prop to all children
  recursiveCloneChildren(children: any) {
    return React.Children.map(children, child => {
      let childProps: any = {};
      if (React.isValidElement(child)) {
          childProps = {layout: this.props.layout};
      }
      if (child && child.props) {
          childProps.children = this.recursiveCloneChildren(child.props.children);
          return React.cloneElement(child, childProps);
      }
      return child;
    });
  }

  render() {

    return (
      <StyledCarouselContainer>
        {
          this.recursiveCloneChildren(this.props.children)
        }
      </StyledCarouselContainer>
    );
  }
}