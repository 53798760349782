import React from 'react';
import { StyledPrivacyPage, 
         StyledPrivacyHeading, 
         StyledPrivacyIntro, 
         StyledContentContainer, 
         StyledContent,
         StyledContentHeading,
         StyledContentText, 
        //  StyledContentBulletPoints,
         StyledIcon,
         StyledButtonContainer,
         StyledGoToTopButton } from './TermsConditions.styled';
import { ReactComponent as Arrow } from '../../images/icons/right_icon.svg';

export const TermsConditionsPage = (props: any) => {

    const introText = `These Terms and Conditions form an agreement between you and Movember Europe (Registered Charity number 1137948) (Movember), and is subject to the laws of England and Wales.
    
These Terms and Conditions contain important information that apply to your use of this website, including warranty disclaimers and limitations of liability. By using this website, you agree to be bound by these Terms and Condition.
`;
    
    const disclaimer = `The content on the website is for general informational purposes only. It is not intended to be a substitute for advice from an appropriately qualified healthcare professional, who can determine your individual needs. You must make your own assessment of the information contained on this website and you rely on it at your own risk.`;
    const intellectualProperty = `Movember owns or licenses from third parties all of the trademarks, copyright and other intellectual property published on this website. We are happy for you to download material from this website for personal and non-commercial purposes, or for purposes permitted by law, provided any reproduction is unaltered, dated and includes an attribution of source.

You must not otherwise copy or reproduce in any form any of the material from this website, including Movember’s trademarks and logos, unless you have our prior written consent.  All intellectual property contained on this website is regulated by the laws of England and Wales.

If you believe that someone else is using your copyright or trademark without your permission, please contact us at generalcounsel@movember.com to report the infringement.
`;

    const thirdParty = `This website contains links to third party websites. Movember does not control those websites and is not responsible for the content or accuracy of the material they contain. Provision of these links does not mean that Movember sponsors, approves or endorses the content on the third party website.`;
    // const entitiesGovLaw = `The Movember entity and relevant law governing these Terms and Conditions depends on the country in which you live:`;
    // const bullets = 
    // `a. If you live in Canada, these Terms and Conditions form an agreement between you and Movember Canada (Co. No. 767531-3), subject to the laws of Ontario, Canada;
    
    // b.	If you live in the USA, these Terms and Conditions form an agreement between you and Movember Foundation (EIN 77-07114052) subject to the laws of California, USA;
    
    // c.	If you live in the UK or a country that forms part of the EEA (except for Germany), these Terms and Conditions form an agreement between you and Movember Europe (Co. No. 275694), subject to the laws of England and Wales;
    
    // d.	If you live in New Zealand, these Terms and Conditions form an agreement between you and the Movember Foundation New Zealand (Co. No. 2616293), subject to the laws of New Zealand;
    
    // e.	If you live in Germany, these Terms and Conditions form an agreement between you and Movember Foundation e.V, subject to the laws of Germany; and
    
    // f.	If you live in Australia, or in any other place not covered by paragraphs a, b, c, d or e above, these Terms and Conditions form an agreement between you and The Movember Group Pty Ltd as trustee for the Movember Foundation (ABN 48 894 537 905), subject to the laws of Victoria, Australia.`;
    const changesToTCs = `Movember may vary these Terms and Conditions at any time by publishing a revised version on this website. Your continued use of this website following such variation will represent an agreement by you to be bound by the Terms and Conditions as amended.`;
    
    const ToTcsAddition = `The content on the website is for general informational purposes only. It is not intended to be a substitute for advice from an appropriately qualified healthcare professional, who can determine your individual needs. You must make your own assessment of the information contained on this website and you rely on it at your own risk.`;

    return (
        <StyledPrivacyPage>
            <StyledPrivacyHeading level="h4">TERMS & CONDITIONS</StyledPrivacyHeading>
            <StyledPrivacyIntro tag="p" marginBottom="0">
                { introText }
            </StyledPrivacyIntro>
            <StyledContentContainer>
                <StyledContent>
                    <StyledContentHeading level="h6" fontSize="30px" marginBottom="12px">
                        1. DISCLAIMER
                    </StyledContentHeading>
                    <StyledContentText tag="p"> { disclaimer } </StyledContentText>
                </StyledContent>

                <StyledContent>
                    <StyledContentHeading level="h6" fontSize="30px" marginBottom="12px">
                        2. INTELLECTUAL PROPERTY
                    </StyledContentHeading>
                    <StyledContentText tag="p"> { intellectualProperty } </StyledContentText>
                </StyledContent>

                <StyledContent>
                    <StyledContentHeading level="h6" fontSize="30px" marginBottom="12px">
                        3. THIRD PARTY
                    </StyledContentHeading>
                    <StyledContentText tag="p" marginBottom="59px"> { thirdParty } </StyledContentText>
                </StyledContent>

                {/* <StyledContent>
                    <StyledContentHeading level="h6" fontSize="30px" marginBottom="12px">
                        4. ENTITIES AND GOVERNING LAW
                    </StyledContentHeading>
                    <StyledContentText tag="p" marginBottom="19px"> { entitiesGovLaw } </StyledContentText>
                    <StyledContentBulletPoints marginBottom="59px">{ bullets }</StyledContentBulletPoints>
                </StyledContent> */}

                <StyledContent>
                    <StyledContentHeading level="h6" fontSize="30px" marginBottom="12px">
                        4. CHANGES TO THESE TERMS & CONDITIONS
                    </StyledContentHeading>
                    <StyledContentText tag="p" marginBottom="19px"> { changesToTCs } </StyledContentText>
                </StyledContent>

                <StyledContent>
                    <StyledContentText tag="p" marginBottom="0px"> { ToTcsAddition } </StyledContentText>
                </StyledContent>

            </StyledContentContainer>
            <StyledButtonContainer>
                <StyledGoToTopButton onClick={gotoTop}>
                    <StyledIcon>
                        <Arrow />
                    </StyledIcon>
                </StyledGoToTopButton>
            </StyledButtonContainer>
        </StyledPrivacyPage>
    );
};

function gotoTop(): void {
    if (window.scrollY > 0) {
        setTimeout(() => {
            window.scrollTo(0, window.scrollY - 150);
            gotoTop();
        }, 5);
    }
}