import Athlete from '../images/research/AotG_Athlete.svg';
import Coaches from '../images/research/AotG_coach.svg';
import Parents from '../images/research/AotG_Parents.svg';
import Success from '../images/research/AotG_Plan.svg';
import Research from '../images/research/AotG_research.svg';
import Help from '../images/research/AotG_Mate.svg';


export const ResearchContent = {
    heading: 'Research',
    subHeading: `Sport is a popular and engaging context for young men. Back in 2015, we set out to answer the question of whether sports could be used to teach young men about mental health and help them enhance their wellbeing.
    
Young athletes, coaches, parents and team managers were all consulted about the types of mental health programmes that would suit their needs.
    
These findings are now published and you can access summaries below.
    `
};

export const researchPanels = [
    {
      image: Research,
      title: 'Mental Health Literacy & Resilience',
      text: `The Ahead of the Game programme increases depression literacy, anxiety literacy, intentions to seek help from formal sources, confidence to seek mental health information, resilience, and wellbeing.

It equips young men who participate in organised sports with the knowledge, intentions, confidence, and psychosocial resources necessary to promote mental health, and support better identification of and respond to mental health problems.`,
      link: 'https://journals.lww.com/acsm-msse/Fulltext/2021/01000/An_Intervention_for_Mental_Health_Literacy_and.17.aspx',
      linkText: 'Full publication here'
    },
    {
      image: Athlete,
      title: 'Young Athletes & Mental Health',
      text: `We found that young men didn't talk about mental health with their friends. However, they thought that sport would be an engaging setting to learn about mental health.
      
Young men also thought that certain people such as coaches, parents and elite athletes could be key to supporting mental health in the sports setting.
      `,
      link: 'https://www.sciencedirect.com/science/article/pii/S1469029217305770',
      linkText: 'Full publication here'
    },
    {
      image: Parents,
      title: 'Parents & Youth Mental Health',
      text: `We found that parents worry about their child potentially developing a mental health problem. On top of that, they worry about their own ability to then respond to it.

Research found that parents wanted more mental health information in general. They also wanted the skills to be able to recognise the difference between common adolescent behaviour and symptoms of a more serious problem.
      `,
      link: 'https://www.tandfonline.com/doi/abs/10.1080/2159676X.2016.1275751',
      linkText: 'Full publication here'
    },
    {
      image: Coaches,
      title: 'Coaches & Youth Mental Health',
      text: `We found that coaches perceived their role as a youth sport coach to be diverse — including their role in promoting mental health. Some coaches preferred to work directly with athletes, whereas others preferred to communicate directly with parents.

Overall there was a high need for coaches to receive education on how to recognise the warning signs of mental health problems.
      `,
      link: 'https://www.tandfonline.com/doi/abs/10.1080/10413200.2018.1466839',
      linkText: 'Full publication here'
    },
    {
      image: Help,
      title: 'The \'Help Out a Mate\' Programme',
      text: `We found that athletes who attended the programme showed:

• decreased stigmatising attitudes
• increased intentions to help a teammate
• increased knowledge about anxiety and depression
      
No negative side-effects of the programme were evident throughout the testing and research.
      `,
      link: 'https://www.tandfonline.com/doi/full/10.1080/10413200.2019.1653404',
      linkText: 'Full publication here'
    },
    {
      image: Success,
      title: 'Implementing a Successful Programme',
      text: `The most successful results from running the programme came from working with sporting teams that:

• are ready and have available resources to run the programme
• have knowledgeable staff that understand the way local teams work
• have staff knowledgeable in mental health programmes      

All of these criteria proved majorly beneficial in running the programme.
      `,
      link: 'https://www.sciencedirect.com/science/article/pii/S1469029218307477',
      linkText: 'Full publication here'
    }
  ];