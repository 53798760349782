import React, { Component } from 'react';

import DiagonalSplitImage from '../../images/about/About_header_desktop.png';
import DiagonalSplitImageTablet from '../../images/about/About_header_tablet.png';
import DiagonalSplitImageMobile from '../../images/about/About_header_mobile.png';

import { Text, Button, Heading } from '@movember/mo-gel';
import { ReactComponent as RightArrow } from '../../images/icons/right_icon.svg';
import { CallToAction } from '../../typings/CallToAction.type';
import { DiagonalSplitBanner } from '../../components/DiagonalSplitBanner/DiagonalSplitBanner';
import { StyledAboutPage, 
  StyledAboutHowItWorks, 
  StyledActionContainer,
  StyledButtonContents,
  StyledSubSection
 } from './About.styled';
import { Size } from '../../typings/Size.type';
import { HalfImageHalfText } from '../../components/HalfImageHalfText/HalfImageHalfText';
import HalfImage from '../../images/about/halfimage.png';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { HowItWorksContent, WhyItWorksContent } from '../../contents/abouteSections';
import { DocumentarySection } from '../../components/DocumentarySection/DocumentarySection';

class AboutPage extends Component<any, any> { 

  renderSubections(subsections: Array<any>) {
    return subsections.map((section: any, index: any) => 
      <StyledSubSection>
        <Heading level="h6" color={this.props.theme.colors.black || "black"} fontFamily={this.props.theme.defaultFontFamily || "Passion One"} marginBottom="16px">
          { section.subheading }
        </Heading>
        { section.link !== "" ? (
          <Button
            primary={true}
            width="194px"
            onClick={() => this.props.history.push(section.link)}
          >
            <StyledButtonContents>
              { section.text }<RightArrow/>
            </StyledButtonContents>            
          </Button>
        ) : (
          <Text color="black">
            { section.text }
          </Text>
        )}
      </StyledSubSection>
    )
  }

  render() {

  const diagonalBannerCallToAction: CallToAction = {
    heading: 'ABOUT',
    subHeading: `Ahead of the Game uses sport to teach athletes, parents and coaches how to talk about mental health. The programme reviews how to recognise mental health issues, what to do and when to get help. Athletes learn how to build resilience and overcome challenges in sport and life.
    `
  };

  const slides = [
    {
      image: HalfImage,
      heading: 'ABOUT MOVEMBER',
        // tslint:disable-next-line: max-line-length
      subHeading: `Movember is the leading charity dedicated to changing the face of men’s health around the world. With a singular goal to stop men dying too young, Movember supports the following causes: prostate cancer, testicular cancer, mental health and suicide prevention. Since 2003, the support of more than 5 million participants has funded over 1,200 innovative projects across more than 20 countries.
      
Learn more about `,
      link: 'Movember'
    }
      
  ];

  return (
    <StyledAboutPage>
      <DiagonalSplitBanner callToAction={diagonalBannerCallToAction} images={[DiagonalSplitImage, DiagonalSplitImageTablet, DiagonalSplitImageMobile]} size={Size.small} />
      <StyledAboutHowItWorks>
        <StyledActionContainer>
          <Heading level="h2" color={this.props.theme.colors.black || "black"} fontFamily={this.props.theme.defaultFontFamily || "Passion One"}marginBottom="20px">
            { WhyItWorksContent.heading }
          </Heading>
          <Text color="black">
            { WhyItWorksContent.introText }
          </Text>
          { this.renderSubections(WhyItWorksContent.subsections)}
        </StyledActionContainer>
        <StyledActionContainer>
          <Heading level="h2" color={this.props.theme.colors.black || "black"} fontFamily={this.props.theme.defaultFontFamily || "Passion One"} marginBottom="20px">
            { HowItWorksContent.heading }
          </Heading>
          <Text color="black">
            { HowItWorksContent.introText }
          </Text>
          { this.renderSubections(HowItWorksContent.subsections)}          
        </StyledActionContainer>
      </StyledAboutHowItWorks>
      <DocumentarySection />
      <HalfImageHalfText slides={slides} layout="mobileOnTabletPortrait" />
    </StyledAboutPage>
  );
  }
}

const About = withTheme(withRouter(AboutPage));

export { About as AboutPage };