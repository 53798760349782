import styled from 'styled-components';
import { Heading } from '@movember/mo-gel';

export const StyledMainContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 74px 0 63px;
    overflow: hidden;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        padding: 95px 39px 103px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        padding: 107px 10.28% 112px;
    }
`;

export const StyledMainHeading = styled(Heading)`
    margin-bottom: 27px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        margin-bottom: 36.5px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        margin-bottom: 59px;
    }
`;

export const StyledContentMainContainer = styled.div`
    width: 300%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: ${(props: any) => props.marginLeft}%;
    transition: margin-left .25s linear;

    Button {
        height: auto;
        background: #000000;
        color: #ffffff;
        :hover {
            background: ${(props:any) => props.theme.colors.buttonDarkHover}
        }
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        margin: 0;
        width: 100%;
        button {
            position: absolute;
            bottom: 60px;
        }
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        bottom: 112px;
    }
`;

export const StyledContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 48px;
    margin-left: 0;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        box-sizing: border-box;
        padding-right: 0;
        padding-bottom: 0;
        min-width: 210px;
        justify-content: flex-start;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        min-width: 360px;
        padding: 0 16px;
        align-items: flex-start;
    }
`;

export const StyledImage = styled.div`
    height: 218px;
    width: 100%;
    background: url(${(props: any) => props.image}) no-repeat center;
    background-size: contain;
    margin-bottom: 48px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        height: 158px;
        margin-bottom: 28px;
        background-size: cover;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        min-height: 271px;
        margin-bottom: 33px;
        background-size: cover;
    }
`;

export const StyledContentHeading = styled(Heading)`
    margin-bottom: 17px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        margin-bottom: 13px;
    }
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
    white-space: pre-wrap;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        align-items: flex-start;
        text-align: left;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        align-items: flex-start;
        text-align: left;
        justify-content: space-evenly;
    }
`;

export const StyledButtonContents = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    font-weight: 300;
`;

export const StyledIconContainer = styled.div`
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: center;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        display: none;
    }
`;

export const StyledChevron = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    margin: 0 16.5px;
    border-radius: 50%;
    background: ${(props: any) => props.theme.colors.primaryBrand3};

    :after {
        content: ${(props: any) => props.content};
        position: absolute;
        color: ${(props: any) => props.theme.colors.white};
    }
`;