import React, { Component } from 'react';

import { homePageContent } from '../../contents/homepage';

// import DiagonalSplitImage from '../../images/homepage/Homepage_locker_shot.png';
// import DiagonalSplitImageTablet from '../../images/homepage/Homepage_locker_shot_tablet.png';
// import DiagonalSplitImageMobile from '../../images/homepage/Homepage_locker_shot_mobile.png';
import DiagonalSplitImage from '../../images/homepage/homepage_chat_desktop.png';
import DiagonalSplitImageTablet from '../../images/homepage/homepage_chat_tablet.png';
import DiagonalSplitImageMobile from '../../images/homepage/homepage_chat_mobile.png';

import { CallToAction } from '../../typings/CallToAction.type';
import { DiagonalSplitBanner } from '../../components/DiagonalSplitBanner/DiagonalSplitBanner';
import { StyledHomePage } from './Home.page.style';
import { Size } from '../../typings/Size.type';
import { WhoIsThisFor } from '../../components/WhoIsThisFor/WhoIsThisFor';
import { slides } from '../../contents/whoisthisfor';
import { withRouter } from 'react-router-dom';
import { Hero } from '../../components/Hero/Hero';
import HeroImageDesktop from '../../images/page_banners/Homepage-header.png';
import HeroImage from '../../images/page_banners/Homepage-header_mobile.jpg'
import { BannerImage } from '../../typings/BannerImage.type';
import { DocumentarySection } from '../../components/DocumentarySection/DocumentarySection';

class HomePage extends Component<any> { 
  render() {
  const { heroBanner, diagonalBanner } = homePageContent;
  
  const banners: BannerImage[] = [
    {
      bannerUrl: HeroImage,
      breakpoint: 'xxs',
      height: 686,
    },
    {
      bannerUrl: HeroImageDesktop,
      breakpoint: 'xs',
      height: 1920,  // This doesn't matter at the moment I've just set 100vh on any size not mobile.
    }
  ];

  const heroBannerCallToAction: CallToAction = {
    heading: heroBanner.heading.toUpperCase(),
    callToActionLabel: heroBanner.callToActionLabel,
    onClick: (): void => this.props.history.push('/contact')
  };

  const diagonalBannerCallToAction: CallToAction = {
    heading: diagonalBanner.heading.toUpperCase(),
    callToActionLabel: diagonalBanner.callToActionLabel,
    subHeading: diagonalBanner.subHeading,
    onClick: (): void => this.props.history.push('/about')
  };

  return (
    <StyledHomePage>
      <Hero callToAction={heroBannerCallToAction} heroBanners={banners} />
      <DiagonalSplitBanner callToAction={diagonalBannerCallToAction} images={[DiagonalSplitImage, DiagonalSplitImageTablet, DiagonalSplitImageMobile]} size={Size.large} headingLevel="h3"/>
      <WhoIsThisFor slides={ slides }/>
      <DocumentarySection />
    </StyledHomePage>
  );
  }
}

const home = withRouter(HomePage);

export { home as HomePage };