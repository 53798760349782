import React from 'react';
import styled from 'styled-components';

const BannerContainer = styled.div`
  position: relative;
  font-family: AktivGrotesk,sans-serif;
  display: block;
  overflow: hidden;
  font-size: 14px;
  line-height: 21px;
  background: #000000;
  color: #fff;
  width: 100%;
  text-align: center;
  padding: 10px;
  height: auto;
  box-sizing: border-box;

  a {
    color: #3d85c6;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BannerContent = styled.div`
  display: block;
  margin: auto;
`;

export const Banner: React.FC = () => {
  return (
    <BannerContainer>
      <BannerContent>
        Help fund more programs like Movember Ahead of the Game{' '}
        <a target='_blank' rel='noopener noreferrer' href='https://au.movember.com/register'>
          Sign up
        </a>{' '}
        or{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://au.movember.com/donate/details?memberId=99'
        >
          donate
        </a>
      </BannerContent>
    </BannerContainer>
  );
};
