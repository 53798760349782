import styled from 'styled-components';
import { Heading } from '@movember/mo-gel';

export const StyledPartnerSection = styled.div`
  display: block;
  padding: 80px 0;
  background-color: ${(props: any) => props.theme.colors.primaryBrand3};


}
`;

export const StyledLogoContainer = styled.div`  
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;  
  width: 100%;
  height: 100%;
  flex-wrap: wrap;

  @media(min-width: ${(props: any) => props.theme.breakpoints.xl}) {
    height: 220px;
  }
`;

export const Spacer = styled.div`  
  height: 50px;
`;

export const StyledNavigationList = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 0px;
  background-color: ${(props: any) => props.theme.colors.primaryBrand3};  
  margin-left: auto;
  margin-right: auto;
`;

export const StyledHeading = styled(Heading)`
  color: ${(props: any) => props.theme.colors.black};
  font-size: 50px;
  width: 187px;
  height: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  margin: 0 auto;
  padding: 20px 0 40px; 
  text-align: center;
`;
  
