import styled from 'styled-components';
// import { NavLink } from 'react-router-dom';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { Text } from '@movember/mo-gel';

export const StyledSubNavigation = styled.div`
  background: #000000;
  position: absolute;
  display: ${(props: any) => props.hover ? 'flex' : 'none'};
  left: 0;
  width: 100%;
  font-family: ${(props: any) => props.theme.typography.defaultFontFamily} !important;
`;

export const StyledImageNav = styled(NavLink)`
  pointer-events: auto;
  padding: 10px 0;
`;

export const StyledSubNavItem = styled(NavLink)`
  text-decoration: none;
  font-family: ${(props: any) => props.theme.typography.defaultFontFamily} !important;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 40px;
  margin-left: 39%;
  cursor: pointer;
  span {
    font-family: ${(props: any) => props.theme.typography.defaultFontFamily} !important;
  }
  :hover {
    color: ${(props: any) => props.theme.colors.buttonDarkHover};
  }


  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
    margin-left: calc(100% - 960px);
  };

`;

export const StyledLinkItem = styled.a`
  text-decoration: none;
  padding: 25px 30px;
  pointer-events: auto;
  text-transform: uppercase;

  :hover ${StyledSubNavigation} {
    display: flex;
    flex-direction: column;
    top: 121px;
    left: 0;
    width: 100%;
    color: ${(props: any) => props.theme.colors.buttonDarkHover}
  }
`;

export const StyledNavigationItem = styled(NavLink)`
  text-decoration: none;
  padding: 25px 30px;
  pointer-events: auto;

  :hover ${StyledSubNavigation} {
    display: flex;
    flex-direction: column;
    color: ${(props: any) => props.theme.colors.buttonDarkHover}
    top: 121px;
    left: 0;
    width: 100%;
  }
`;

export const StyledNavigationItemText = styled(Text)`
  color: #ffffff;
  font-family: ${(props: any) => props.theme.typography.defaultFontFamily} !important;
  font-size: 26px;
  text-transform: uppercase;
  :hover {
    color: ${(props: any) => props.theme.colors.buttonDarkHover};
  }
`;  

export const StyledHeader = styled.header`
  position: absolute;
  top: 41px;
  left: 0;
  right: 0;
  height: 121px;
  display: flex;
  padding: 0 51px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  pointer-events: none;
  transition: all 0.2s ease-in-out;

  :hover {
    background-color: #ffffff;

    ${StyledNavigationItemText} {
      color: #000000;
      :hover {
        color: ${(props: any) => props.theme.colors.buttonDarkHover};
      }
    }

    ${StyledNavigationItem} {
      :hover {
        color: ${(props: any) => props.theme.colors.accentLink};
        border-bottom: 4px solid ${(props: any) => props.theme.colors.buttonDarkHover};
      }
    }
  }
`;

export const StyledNavigationList = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 100%;
`;