import styled, { css } from 'styled-components';

export const StyledButtonContents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: #000000;
`;

export const StyledCallToActionContainer = styled.div`
  white-space: pre-wrap;
  button {
    background: #ffffff;
    color: #000000;
    height: auto;
    text-transform: uppercase;

    svg {
      g{
        g{
          path{
            fill: #000000;
          }
        }
      }
    }

    :hover {
      ${(props: any) =>
        css`
          color: ${props.theme.colors.primaryBrand1};
          background-color: ${props.theme.colors.buttonLightHover};
          svg {
            g {
              g {
                path {
                  fill: ${props.theme.colors.primaryBrand1};
                }
              }
            }
          }
        `
      }
    }
  }
`;