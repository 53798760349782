import styled, { css } from 'styled-components';

const getFontSettings = () => css`
    font-family: ${(props: any) => props.theme.typography.contentFontFamily}, sans-serif;
    font-size: 16px;
    line-height: 1.63;
    color: black;
`;

export const StyledContactUsContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 96px 16px 104px 14px;
    background: ${(props: any) => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        padding: 78px 39px 77px 23px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        padding: 100px 248px 101px 230px;
    }
`;

export const StyledForm = styled.form`
    button {
        height: 50px;
        width: 161px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const StyledContactInfoContainer = styled.div`
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        align-items: flex-start;
        width: 49%;
    }
`;

export const StyledBottomContainer = styled.div`
    padding: 0;
    margin-top: 20px;
    height: 464px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        margin: 0;
    }

    button {
        background-color: ${(props: any) => props.theme.colors.primaryBrand1};
        color: white;
        text-transform: uppercase;
    }

    button:hover {
        background-color: ${(props: any) => props.theme.colors.buttonDarkHover};
      }
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        justify-content: space-between;
        align-items: flex-end;
        height: 439px;
        width: 43%;
    }
`;

export const StyledFieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${(props: any) => props.align ? props.align : 'normal'};
    width: 100%;
`;

export const StyledLabel = styled.label`
    width: auto;
    height: 30px;
    ${getFontSettings()}
    font-weight: bold;
    color: ${(props: any) => props.error ? props.theme.colors.error : 'black'};
`;

export const StyledInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    height: 39px;
    opacity: 0.45;
    border-radius: 19.5px;
    background-color: ${(props: any) => props.error ? props.theme.colors.error : props.theme.colors.primaryBrand2};
    outline: none;
    border: none;
    padding: 0 49px 0 16px;
    ${getFontSettings()}
`;

export const StyledTextareaContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 344px;
    margin-bottom 4px;
    border: solid 2px ${(props: any) => props.theme.colors.black};
    background-color: ${(props: any) => props.error ? '#ffa6a6' : "#fff"};
    padding: 14px 41px 0 14px;
`;

export const StyledTextarea = styled.textarea`
    width: 100%;
    height: 340px;
    resize: none;
    outline: none;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    ${getFontSettings()}

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: ${(props: any) => props.theme.colors.black};
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: ${(props: any) => props.theme.colors.black};
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: ${(props: any) => props.theme.colors.black};
    }
    :-moz-placeholder { /* Firefox 18- */
        color: ${(props: any) => props.theme.colors.black};
    }
`;

export const StyledButtonContents = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
`;